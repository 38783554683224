@import '../../styles/variables';
@import '../../styles/mixins';

.details {
  @include linear-gradient(#fff, #fff);
}

.panel {
  padding: 0 20px;
  @include media-breakpoint-range (tab-up) {
    padding: 0 5%;
  }
}

.button.circle{
  background-color: #000!important;
}
