@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }  
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }  
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};      
}

@mixin box-shadow($level) {
  @if $level == level-1 {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.16);
  }
  @if $level == level-2 {
    box-shadow: 0 3px 6px rgba(0,0,0,0.12), 0 3px 6px rgba(0,0,0,0.16);
  }
  @if $level == level-2-invert {
    box-shadow: 0 -3px 6px rgba(0,0,0,0.12), 0 -2px 6px rgba(0,0,0,0.16);
  }
  @if $level == level-3 {
    box-shadow: 0 10px 20px rgba(0,0,0,0.12), 0 6px 6px rgba(0,0,0,0.16);
  }
  @if $level == level-4 {
    box-shadow: 0 14px 28px rgba(0,0,0,0.12), 0 10px 10px rgba(0,0,0,0.16);
  }
  @if $level == level-5 {
    box-shadow: 0 19px 38px rgba(0,0,0,0.09), 0 15px 12px rgba(0,0,0,0.16);
  }
}

@mixin media-breakpoint-range($device) {
  @if $device == small-mobile {
    @media (max-width: 370px) { @content; }
  }
  @if $device == mobile {
    @media (max-width: 599px) { @content; }
  }
  @if $device == mobile-landscape {
    @media (orientation: landscape) and (max-width: 670px)   { @content; }
  }
  @else if $device == tab-up {
    @media (min-width: 600px) { @content; }
  }
  @else if $device == tab-landscape-up {
    @media (min-width: 900px) { @content; }
  }
  @else if $device == desktop-up {
    @media (min-width: 1200px) { @content; }
  }
  @else if $device == large-display-up {
    @media (min-width: 1800px) { @content; }
  }
}

@mixin linear-gradient ($start, $end) {
  background: $start;
  background: -moz-linear-gradient(left, $start 0%, $end 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, $start), color-stop(100%, $end));
  background: -webkit-linear-gradient(left, $start 0%, $end 100%);
  background: -o-linear-gradient(left, $start 0%, $end 100%);
  background: -ms-linear-gradient(left, $start 0%, $end 100%);
  background: linear-gradient(to right, $start 0%, $end 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start', endColorstr='$end', GradientType=1 );
}

.disconnect-box > .modal-content  {
  border-radius: 32px;
  background-color: #fff;
  border: 1px solid #000;
 
  width: 530px;
}
.short-bnr{
  margin-top: 30px;
  border: 1px solid #000;
    border-radius: 50px;
}
.connectbtns > p{
  margin: 0px;
  color: #000;
  margin-top: 7px;
  text-align: center;
  margin-left: -4px;
}
.walletText {
  font-size: 20px;
}
.connect-box > .modal-content  {
  border-radius: 32px;

  
  width: 350px;
}
.depositPage_title__gDnka{
  color: #fff;
}
.bottomtext a {
 color: #001f68;
  text-decoration: none;
}
.bottomtext a:hover {
  text-decoration: none;
  color: #001f68;
}
.bottomtext {
  text-align: center;
  margin-top: 35px;
}
.modal-header .close {
  color: #585858;
}
 
.connectbtn {
  background-color: #fff;
  border-radius: 8px;
  padding: 10px 15px;
  color: #001f68;
  text-align: center;
  font-size: 15px;
  letter-spacing: 0.25px;
  width: 100%;
  font-weight: 700;
  border: 1px solid #001f68;
  display: block;
  transition: all 0.36s ease;
  cursor: pointer;
  
}
.connectbtns {
  background-color: #e6dafa;
  height: 98px;
  border-radius: 100%;
  padding: 25px 25px;
  color: #fff;
  text-align: center;
  font-size: 15px;
  letter-spacing: 0.25px;
  width: 98px;
  font-weight: 700;
  border: none !important;
  display: block;
  transition: all 0.36s ease;
  cursor: pointer;
  margin-left: 10px;
}

.roiarea2{
  display: flex;
    justify-content: center;
}
.connectbtns:active {
  background: radial-gradient(ellipse at center,#35b0e6 0,#5d44a5 91%,#da07a2 100%);
      background-color: rgba(0, 0, 0, 0);
}
.connectbtns:hover {
  box-shadow: 0 5px 12px 4px #f0f;
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}
.bsc-wrp{
  display: block;
  text-align: right;
  margin-right: 80px;
  margin-top: 33px;
  
}
.dropdown{
  display: inline-block;
}
.dropdown-toggle::after{
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 8px;
  vertical-align: middle;
  border-top: 6px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.dropdown-item{
  margin-left: 0px! important;
  color: #fff! important;
  font-size: 14px! important;
  padding: 10px! important;
}

.dropdown-menu.show {
  display: block;
    background-color: #161654;
    width: 100%;
    margin-top: 10px;
    border-radius: 8px;
}
.dropdown-item:focus, .dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: transparent! important;
}
.btn:hover{
  color: #fff! important;
}
.bsc{
  width: 22px;
  margin-right: 5px;
}
.bsc-wrp-mobile{
  display: none! important;
}
.header_header__2bej3 .header_burger__Gy1po {
  top: -38px! important;
}
.twitter-white{
  display: none;
}
.button:hover .twitter-white{
    display: block;
    margin: auto;
}
.button:hover .twitter-black{
  display: none;
}
@media only screen and (max-width: 1140px) {
  .button{
    padding: 0 10px;
  }
  
}
@media only screen and (max-width: 992px) {
  .header_header__2bej3 .header_burger__Gy1po {
    top: -90px !important;
}
 .bsc-wrp-mobile{
   display: inline-block! important;
 }

 .bsc-wrp{
   display: none! important;
 }
}

@media only screen and (max-width: 580px) {
  .header_header__2bej3 .header_burger__Gy1po {
    top: -56px !important;
}
 }


 .connectbtns img {
  max-width: 25px;
}


.navigation_navigation__PZx0m button{
  background-color: #fff!important;
  color: #000!important;
  border: 1px solid #000!important;
}
.navigation_navigation__PZx0m button.navigation_active__uperw{
  color: #fff!important;
}