@import "../../styles/variables";
@import "../../styles/mixins";

.features {
  background-color: #f1f2f2;
}

.feature {
  display: flex;
  padding: 0px 20px;
  align-items: center;
  flex-direction: column;

  &:hover {

    .frame {
      &::before,
      &::after {
        width: 165px;
      }

      &::before {
        height: 170px;
        opacity: 0.75;
      }

      &::after {
        height: 140px;
        opacity: 0.75;
      }
    }
  }

  h4 {
    font-size: 18px;
    font-family: $regular;
    font-weight: bold;
    letter-spacing: 3px;
    margin-bottom: 30px;
    text-align: center;
  }

  p {
    text-align: center;
    font-size: 15px;
    font-family: $bold;
  }

  .frame {
    display: flex;
    position: relative;
    align-items: center;
    height: 250px;
    justify-content: center;
    width: 100%;

    @include media-breakpoint-range(desktop-up) {
      height: 300px;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 130px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.36s ease;
      position: absolute;

      @include media-breakpoint-range(desktop-up) {
        width: 0px;
      }
    }

    &::before {
      background: rgba(53, 176, 230, 1);
      background: -moz-linear-gradient(
        top,
        rgba(53, 176, 230, 1) 0%,
        rgba(93, 68, 165, 1) 47%,
        rgba(218, 7, 162, 1) 100%
      );
      background: -webkit-gradient(
        left top,
        left bottom,
        color-stop(0%, rgba(53, 176, 230, 1)),
        color-stop(47%, rgba(93, 68, 165, 1)),
        color-stop(100%, rgba(218, 7, 162, 1))
      );
      background: -webkit-linear-gradient(
        top,
        rgba(53, 176, 230, 1) 0%,
        rgba(93, 68, 165, 1) 47%,
        rgba(218, 7, 162, 1) 100%
      );
      background: -o-linear-gradient(
        top,
        rgba(53, 176, 230, 1) 0%,
        rgba(93, 68, 165, 1) 47%,
        rgba(218, 7, 162, 1) 100%
      );
      background: -ms-linear-gradient(
        top,
        rgba(53, 176, 230, 1) 0%,
        rgba(93, 68, 165, 1) 47%,
        rgba(218, 7, 162, 1) 100%
      );
      background: linear-gradient(
        to bottom,
        rgba(53, 176, 230, 1) 0%,
        rgba(93, 68, 165, 1) 47%,
        rgba(218, 7, 162, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#35b0e6', endColorstr='#da07a2', GradientType=0 );
      box-shadow: 0px 0px 35px 15px rgba($color: $glowing-button, $alpha: 1);
      height: 135px;
      opacity: 0.75;

      @include media-breakpoint-range(desktop-up) {
        height: 0px;
        opacity: 0;
      }
    }

    &::after {
      z-index: 1;
      @include linear-gradient(#fff, #fff);
      height: 110px;
      opacity: 0.75;

      @include media-breakpoint-range(desktop-up) {
        height: 0px;
        opacity: 0;
      }
    }

    .icon {
      width: auto;
      position: relative;
      z-index: 2;
      max-width: 50px;

      @include media-breakpoint-range(desktop-up) {
        max-width: 75px;
      }
    }
  }
}
