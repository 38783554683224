@import "../../styles/variables";
@import "../../styles/mixins";

.tokenomics {
  @include linear-gradient(#f1f2f2, #f1f2f2);

  p {
    font-size: 19px;
    margin-bottom: 40px;
    max-width: none;

    @include media-breakpoint-range (tab-landscape-up) {
      max-width: 80%;
    }
  }
}
