@import "../../styles/mixins";
@import "../../styles/variables";

%h4 {
    font-family: $regular;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 1.5px;
    margin-bottom: 25px;
  }
.content{
  background: linear-gradient(45deg, rgba(195,12,159,1) 0%, rgba(89,61,161,1) 57%, rgba(47,127,194,1) 100%);
  padding: 6px 14px;
  .txtAgn{
    height: 40px;
  }
  .btnAlgn{
   float: right;
   padding-right: 15px;
 }
} 
.body {
    background-color: $dark-purple;
    padding: 0px 5px;

    .section1{
        @include linear-gradient(#fff, #fff);
        display: flex;
        padding: 26px 0px;
       
        .half{
            flex: 0 0 50%;
            max-width: 50%;
            align-self: center;
                
        }
        .col{
          padding-right: 0px;
        }
        .sec1_row{
            display: flex;
            margin-bottom: 30px;
            .logo {
              img {
                max-width: none;
                margin: 1px 0 0 16px;
                display: block;
              }
            }
            .social {
              padding: 0 20px;
              h4 {
                @extend %h4;
              }
              h5 {
                font-family: $regular;
                font-size: 16px;
                font-weight: normal;
                margin-top: 40px;
                line-height: 24px;
                text-transform: none;
              }
              .buttons {
                a {
                  &:not(:first-child) {
                    margin-left: 16px;
                  }
                }
                button {
                  img {
                    width: 24px;
                  }
                }
              }
            }    
        }
        .inSec{
          padding: 10px 20px;
        }
        .sec1_row2{
            display: flex;
            margin-top: 30px;
        }
        .row2_item1{
            flex: 0 0 30%;
        }
        .badgePink {
            background-color: $pink;
            color: #ffffff;
            height: 30px;
            border-radius: 10px;
            padding: 2px 20px;
            font-size: 18px;
        }
        .mt30{
            margin-top: 30px;
            margin-right: 30px;
        }
        .mb30{
            margin-bottom:30px;
        }
        
    }

    .section2{
      padding: 30px 0px;
      .colsect2{
        padding: 100px 30px;
        h4{
          text-transform: none;
        }
      }
      .colsect2_1{
        padding: 0 30px;
      }
      .row2_item1{
        display: inline-flex;
        //padding: 0 42px;
      }  
        .stats {
            padding: 30px 30px;
            border-radius: 20px;
            box-shadow: 0px 0px 20px 6px rgba($color: $glowing-purple, $alpha: 1.0);
            margin-top: 40px;
            background: rgba(195,12,159,1);
            background: -moz-linear-gradient(45deg, rgba(195,12,159,1) 0%, rgba(89,61,161,1) 57%, rgba(47,127,194,1) 100%);
            background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(195,12,159,1)), color-stop(57%, rgba(89,61,161,1)), color-stop(100%, rgba(47,127,194,1)));
            background: -webkit-linear-gradient(45deg, rgba(195,12,159,1) 0%, rgba(89,61,161,1) 57%, rgba(47,127,194,1) 100%);
            background: -o-linear-gradient(45deg, rgba(195,12,159,1) 0%, rgba(89,61,161,1) 57%, rgba(47,127,194,1) 100%);
            background: -ms-linear-gradient(45deg, rgba(195,12,159,1) 0%, rgba(89,61,161,1) 57%, rgba(47,127,194,1) 100%);
            background: linear-gradient(45deg, rgba(195,12,159,1) 0%, rgba(89,61,161,1) 57%, rgba(47,127,194,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c30c9f', endColorstr='#2f7fc2', GradientType=1 );
            margin-bottom: 30px;
          
            .value {
              font-size: 26px;
              margin-bottom: 0;
            }
            small{
              font-size: 15px;
            }
        }

        .buttonSpace{
            margin: 10px;
        }
    }

    .section3{
      padding: 30px 0px;
        .details {
            @include linear-gradient(#0f0230, #161654);
            section{
              padding: 50px 0px;
            }
          }
          
          .panel {
            //padding: 0 20px;
            @include media-breakpoint-range (tab-up) {
              padding: 0 60px;
            }
          }
    }

    
}