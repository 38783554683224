@import '../../styles/variables';
@import '../../styles/mixins';

.details {
  @include linear-gradient(#fff, #fff);
}

.panel {
  padding: 0 20px;
  @include media-breakpoint-range (tab-up) {
    padding: 0 5%;
  }
}
.mb25{margin-bottom: 25px;}
.pb{padding-bottom: 0;}



.timer {
  //    flex-grow: 1;
    background-color: #fff;
    //  height: 60px;
    padding:10px;
     color: #000;
     display: flex;
     border: none;
     padding-left: 5px;
     text-align: center;
     padding-right: 5px;
     transition: all 0.36s ease;
     border-radius: 10px;
     width: 40%;
     margin-bottom: 5px;
     font-size: 25px;
     border: 2px solid #000;
     
     z-index: 1;
   
     
     @include media-breakpoint-range (tab-up) {
      margin: 0 -15px;
     }
 
   
   }
   
   @media screen and (max-width: 580px) {
     h5{font-size: 16px;}
     .timer{margin-left: 15px;
      width: 100%;
      margin-top: 15px;}
   }