@import '../../styles/variables';
@import '../../styles/mixins';
.section1 {
    padding: 0px;
  }
.card1{    background: linear-gradient(to right, #f1f2f2 0%, #f1f2f2 100%);
  padding: 40px;
  border-radius: 50px;
  margin-top: 10%;
  padding-left: 8%;
  padding-right: 5%;}
.card1 .title{color:$light-blue;font-size: 16px;}
.card1 .value{font-size: 20px;}
.card1 .btnTab{position: relative;
//   top: 15%;
  float: right;}
.card1 .btnTab .text{text-align: right;
  margin-right: 15px;
  font-size: 16px;margin-bottom: 5px;
}
.hdr{margin-top: 5%;
  padding-left: 2%;text-transform: capitalize;}
.card2{background: $tab-button;
  padding: 50px 30px;
  border-radius: 50px;margin-top: 2%;}
.card2 .col_moon{height: 200px;max-width: 20%;
  border-radius: 30px;
  box-shadow: 0px 0px 12px 4px rgba($color: $glowing-purple, $alpha: 1);    margin-left: 2.5%;
  margin-right: 2.5%;padding: 30px;
}
.card2 .col_moon h5{color:$glowing-purple ;margin-bottom: 15px;}
.card2 .col_mar{height: 200px;max-width: 20%;
  border-radius: 30px;
  box-shadow: 0px 0px 12px 4px rgba($color: $pink, $alpha: 1);    margin-left: 2.5%;
  margin-right: 2.5%;padding: 30px;
}
.card2 .col_mar h5{color:$pink ;margin-bottom: 15px;}
.card2 .col_star{height: 200px;max-width: 20%;
  border-radius: 30px;
  box-shadow: 0px 0px 12px 4px rgba($color: $blue, $alpha: 1);    margin-left: 2.5%;
  margin-right: 2.5%;padding: 30px;
}
.card2 .col_star h5{color:$blue ;margin-bottom: 15px;}
.card2 .col_galaxy{height: 200px;max-width: 20%;
  border-radius: 30px;
  box-shadow: 0px 0px 12px 4px rgba($color: $light-blue, $alpha: 1);    margin-left: 2.5%;
  margin-right: 2.5%;padding: 30px;
}
.card2 .col_galaxy h5{color:$light-blue ;margin-bottom: 15px;}
.card2 p{font-size: 20px;}

@media screen and (max-width: 580px) {
    .card2 .col_moon{height: 200px;max-width: 190px;
        border-radius: 30px;
        box-shadow: 0px 0px 12px 4px rgba($color: $glowing-purple, $alpha: 1);    
        margin-left: 20.5%;
        margin-right: 4.5%;padding: 30px;
      }
      .card2 .col_moon h5{color:$glowing-purple ;margin-bottom: 15px;}
      .card2 .col_mar{height: 200px;max-width: 190px;
        border-radius: 30px;
        box-shadow: 0px 0px 12px 4px rgba($color: $pink, $alpha: 1);    
        margin-left: 20.5%;
        margin-top: 26px;
        margin-right: 4.5%;
        padding: 30px;
      }
      .card2 .col_mar h5{color:$pink ;margin-bottom: 15px;}
      .card2 .col_star{height: 200px;max-width: 190px;
        border-radius: 30px;
        box-shadow: 0px 0px 12px 4px rgba($color: $blue, $alpha: 1);    
        margin-left: 20.5%;
        margin-top: 26px;
        margin-right: 4.5%;
        padding: 30px;
      }
      .card2 .col_star h5{color:$blue ;margin-bottom: 15px;}
      .card2 .col_galaxy{height: 200px;max-width: 190px;
        border-radius: 30px;
        box-shadow: 0px 0px 12px 4px rgba($color: $light-blue, $alpha: 1);   
        margin-left: 20.5%;
        margin-top: 26px;
        margin-right: 4.5%;
        padding: 30px;
      }  
  }