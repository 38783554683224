@import "../../styles/variables";
@import "../../styles/mixins";

.faq{
    background-color: #fff;
}



.timer {
    //    flex-grow: 1;
       background-color: $tab-button;
      //  height: 60px;
      padding:10px;
       color: white;
       display: flex;
       border: none;
       padding-left: 5px;
       text-align: center;
       padding-right: 5px;
       transition: all 0.36s ease;
       border-radius: 10px;
       width: 40%;
       margin-bottom: 5px;
       font-size: 14px;
       box-shadow: 0px 0px 20px 4px rgba($color: $glowing-purple, $alpha: 1);
       background: rgba(195,12,159,1);
       background: -moz-linear-gradient(45deg, rgba(195,12,159,1) 0%, rgba(89,61,161,1) 57%, rgba(47,127,194,1) 100%);
       background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(195,12,159,1)), color-stop(57%, rgba(89,61,161,1)), color-stop(100%, rgba(47,127,194,1)));
       background: -webkit-linear-gradient(45deg, rgba(195,12,159,1) 0%, rgba(89,61,161,1) 57%, rgba(47,127,194,1) 100%);
       background: -o-linear-gradient(45deg, rgba(195,12,159,1) 0%, rgba(89,61,161,1) 57%, rgba(47,127,194,1) 100%);
       background: -ms-linear-gradient(45deg, rgba(195,12,159,1) 0%, rgba(89,61,161,1) 57%, rgba(47,127,194,1) 100%);
       background: linear-gradient(45deg, rgba(195,12,159,1) 0%, rgba(89,61,161,1) 57%, rgba(47,127,194,1) 100%);
       filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c30c9f', endColorstr='#2f7fc2', GradientType=1 );
       z-index: 1;
       
       @include media-breakpoint-range (tab-up) {
        margin: 0 -15px;
       }
   
     
     }




.box{ display: flex; flex-wrap: wrap; justify-content: center; align-items: center; }
.bitcush{ width: 82%; }
.boxImg{ width: 225px; }
.allocation{ background: linear-gradient(to right, #f1f2f2 0%, #f1f2f2 100%); padding-top: 70px; padding-bottom: 70px; }
.allocationBox{ display: flex; flex-wrap: wrap; justify-content: center; align-items: center;}
.date{ background-color: #fff;
    padding: 30px 42px; width: 310px;
    box-shadow: 0px 0px 12px 4px magenta;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 20px; margin: 0 30px;}
.price{ padding: 30px 42px; width: 492px;
    box-shadow: 0px 0px 12px 4px magenta;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 20px;
    }
.dateSize{ font-size: 19px; margin: 0px; }
.alignBox{ margin-top: 30px; }
.priceSale{ display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between; }
.allocationLimit{ margin-top: 6%; text-align: center; }
.moon{background-color: #fff;
    padding: 30px 42px; width: 160px;
    border: 2px solid #ff00ff;
    box-shadow: 0px 0px 12px 1px magenta;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 20px; margin: 0 30px; margin-top: 4%;  }
.star{
    box-shadow: 0px 0px 12px 4px #5d8aea;
    background-color: #fff;
    padding: 30px 42px; width: 160px;
    border: 2px solid #5d8aea;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 20px; margin: 0 30px; margin-top: 4%; 
}
.nameMoon{ color: #ed02e4; margin: 0; font-size: 18px; font-weight: 600; }
.nameStar{ color: #5d8aea; margin: 0; font-size: 18px; font-weight: 600; }
.bsd{ font-weight: 600; }
.moonBox{ display: flex; flex-wrap: wrap; justify-content: center; align-items: center; margin-top: 4%; }
.wallet{ background: #fff; padding: 65px 0px; }
.connectWallet{ display: flex; flex-wrap: wrap; justify-content: space-around; align-items: center;  }
.balance{ background-color: #fff; max-width: 855px;
    margin: 0 auto; 
    margin-top: 60px;
    padding: 60px 42px 60px;
    box-shadow: 0px 0px 12px 4px magenta;
    border-radius: 20px; }
.yourBalance{ display: flex; flex-wrap: wrap; justify-content: space-between;}
.progress {
    background: linear-gradient(to right, #df05ff 0%, #0968b8 100%)!important; margin-top: 30px!important;
    border-radius: 20px!important;
}
.amountfield{ display: flex; flex-wrap: wrap;
    width: 100%; margin-top: 40px;
    justify-content: space-between;}

    .claim{ display: flex; flex-wrap: wrap;
        width: 100%; margin-top: 40px;
        justify-content: space-between; align-items: center; }

    .buttonBlue{background: #4782ca;
    color: #fff;
    box-shadow: none;
    border: none;
    border-radius: 20px;
    padding: 0px 19px;
    height: 37px;
    margin-left: -86px; }

    .amountfield input[type="text"] {
        width: 66%;
        height: 37px;
        border-radius: 25px;
        background-color: #191A5C;
        border: 1px solid rgba(255, 0, 255, 0.25);
        padding: 0 25px;
        color: white;
        margin-bottom: 20px;
        transition: all 0.36s ease;
    }


    .claim input[type="text"] {
        width: 82%;
        height: 37px;
        border-radius: 25px;
        background-color: #191A5C;
        border: 1px solid rgba(255, 0, 255, 0.25);
        padding: 0 25px;
        color: white;
        margin: 0;
        transition: all 0.36s ease;
    }

    .buttonMax{ background: #4782ca;
        color: #fff;
        box-shadow: none;
        border: none;
        border-radius: 20px;
        padding: 0px 19px;
        height: 37px;
        
    }
    .buttonBuy{ 
            background-color: #fff;
           height: 37px;
            border-radius: 30px;
            padding: 0 25px;
            color: #000;
            border: 1px solid #000;
            font-size: 13px;
            letter-spacing: 0.25px;
            min-width: 101px;
            font-weight: bold;
            
           
            transition: all 0.36s ease; } 

            .p_txt{
                margin: 0; font-size: 28px;
            }

            @media screen and (max-width: 580px) {
                .claim input[type="text"] {
                    width: 100%;
                    height: 37px;
                    border-radius: 25px;
                    background-color: #191A5C;
                    border: 1px solid rgba(255, 0, 255, 0.25);
                    padding: 0 25px;
                    color: white;
                    margin: 0;
                    transition: all 0.36s ease;
                }
                .buttonBuy{
                    margin-top: 20px;
                }
                .price{
                    margin-top: 35px;
                }
                .boxImg{ margin-bottom: 25px; }
                .p_txt{
                  margin-bottom: 20px;
                }
            
            }


            .buttonBuy:hover{
                background-color: #000;
                color: #fff;
                transform: translateY(-5px);
                transition: 0.5s;
            }