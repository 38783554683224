@import "./variables";

.button {
  background-color: #fff;
  height: 48px;
  border-radius: 30px;
  padding: 0 25px;
  color: #000;
  font-size: 13px;
  letter-spacing: 0.25px;
  min-width: 130px;
  font-weight: bold;
  border: none;
  border: 2px solid #000;
  transition: all 0.36s ease;

  &:active {
    background: rgba(53, 176, 230, 1);
    background: -moz-radial-gradient(
      center,
      ellipse cover,
      rgba(53, 176, 230, 1) 0%,
      rgba(93, 68, 165, 1) 91%,
      rgba(218, 7, 162, 1) 100%
    );
    background: -webkit-gradient(
      radial,
      center center,
      0px,
      center center,
      100%,
      color-stop(0%, rgba(53, 176, 230, 1)),
      color-stop(91%, rgba(93, 68, 165, 1)),
      color-stop(100%, rgba(218, 7, 162, 1))
    );
    background: -webkit-radial-gradient(
      center,
      ellipse cover,
      rgba(53, 176, 230, 1) 0%,
      rgba(93, 68, 165, 1) 91%,
      rgba(218, 7, 162, 1) 100%
    );
    background: -o-radial-gradient(
      center,
      ellipse cover,
      rgba(53, 176, 230, 1) 0%,
      rgba(93, 68, 165, 1) 91%,
      rgba(218, 7, 162, 1) 100%
    );
    background: -ms-radial-gradient(
      center,
      ellipse cover,
      rgba(53, 176, 230, 1) 0%,
      rgba(93, 68, 165, 1) 91%,
      rgba(218, 7, 162, 1) 100%
    );
    background: radial-gradient(
      ellipse at center,
      rgba(53, 176, 230, 1) 0%,
      rgba(93, 68, 165, 1) 91%,
      rgba(218, 7, 162, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#35b0e6', endColorstr='#da07a2', GradientType=1 );
  }

  &:hover {
    background-color: #000;
    color: #fff;
    transform: translateY(-5px);
    transition: 0.5s;
  }

  &.circle {
    width: 50px;
    max-width: 50px;
    min-width: auto;
    padding: 0;
    transition: 0.5s;
  }

  &.miniBtn{
    height: 30px;
    padding: 0 12px;
    min-width: 80px;
  }

  &.small {
    min-width: 80px;
    height: 35px;
  }
}
