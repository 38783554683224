@import "../../styles/mixins";
@import "../../styles/variables";

.header {
  background-color: #f1f2f2;
  padding: 0px 75px;
  height: 130px;
  @include media-breakpoint-range (tab-up) {
    height: 130px;
  }
  
  .container {
    height: 100%;
    align-items: center;
  }

  .actions {
    a{
      &:not(:first-child) {
        margin-left: 16px;
      }
    }
  }

  .logo {
    width: 70%;
    max-width: 250px;
    margin: auto;
    display: block;
    
    @include media-breakpoint-range (tab-up) {
      width: 100%;
    }
  }

  .burger {
    width: 40px;
    height: 25px;
    display: flex;
    margin-left: auto;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: -65px;
    right: -35px;

    @include media-breakpoint-range (tab-up) {
      top: -100px;
      right: -25px;
    }
    
    @include media-breakpoint-range (tab-landscape-up) {
      position: relative;
      top: -35px;
      right: 0;
      height: 30px;
    }

    span {
      width: 100%;
      height: 1px;
      background-color: black;
    }
  }

  .transparent {
    background-color: transparent;
    border: none;
  }

  .menu {
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    z-index: 1000;
    background: #d5d5d5;
      
    transition: all 0.3s ease;
    width: 350px;

    
    &.open {
      visibility: visible;
      opacity: 1;
    }
    
    ul {
      margin: 0;
      padding: 0;
      margin-top: 15%;
      margin-left: 60px;
      
      @include media-breakpoint-range (tab-up) {
        margin-top: 5%
      }
      li {
        list-style: none;
        display: block;
        margin-bottom: 20px;
        .wp{
          animation: openModal 0.6s ease-out forwards;
          a{
            font-size: 18px;
          }
          div{
            padding-top: 10px;
            padding-bottom: 10px;
          }
        }
        a {
          color: black;
          cursor: pointer;
          text-decoration: none;
          font-size: 24px;
          font-family: $bold;
          letter-spacing: 2px;
          transition: all 0.2s ease;
          display: block;
          white-space: nowrap;
          .mg{
            width: 22px;
          }
          &:hover {
            transform: translateY(-4px);
            text-shadow: 0px 0px 15px rgba($color: $glowing-purple, $alpha: 1);
          }
          
        }
      }
    }

    @media screen and (max-width: 580px) {
      ul {
        margin: 0;
        padding: 0;
        margin-top: 0%!important;
        margin-left: 60px;
        
        @include media-breakpoint-range (tab-up) {
          margin-top: 5%
        }
        .hide{
          display: none;
        }
        li {
          list-style: none;
          display: block;
          margin-bottom: 14px!important;
          .wp{
            animation: openModal 0.6s ease-out forwards;
            a{
              font-size: 18px;
            }
            div{
              padding-top: 10px;
              padding-bottom: 10px;
            }
          }
          a {
            color: white;
            cursor: pointer;
            text-decoration: none;
            font-size: 24px;
            font-family: $bold;
            letter-spacing: 2px;
            transition: all 0.2s ease;
            display: block;
            white-space: nowrap;
            .mg{
              width: 22px;
            }
            &:hover {
              transform: translateY(-4px);
              text-shadow: 0px 0px 15px rgba($color: $glowing-purple, $alpha: 1);
            }
            
          }
        }
      }
    }

    @keyframes openModal {
      0% { transform: translateY(-50%); }
      100% { transform: translateY(0);   }
    }

    .close {
      margin-top: 60px;
      margin-right: 60px;
      img {
        width: 40px;
      }
    }
  }
}
