@import '../../styles/variables';
@import '../../styles/mixins';
.section1 {
    padding: 0px;
  }
  .card1{background: linear-gradient(to right, #f1f2f2 0%, #f1f2f2 100%);
    padding: 40px;
    border-radius: 50px;
    margin-top:3%;
    padding-left: 8%;
    padding-right: 5%;
    color: #000;
  }
.card1 h1{  background: linear-gradient(
  45deg,
  rgba(218, 7, 162, 1) 0%,
  rgba(93, 68, 165, 1) 47%,
  rgba(53, 176, 230, 1) 100%
);









-webkit-background-clip: text;
-webkit-text-fill-color: transparent;}
  .card1 .title{font-size: 20px;
    margin-bottom: 0;}
  // .card1 .value{font-size: 20px;}
  .card1 .moonBtn{border-radius: 50px;
    padding: 5px 10px;margin: 10px;
    cursor: pointer;

    box-shadow: 0px 0px 12px 4px rgba($color: $glowing-purple, $alpha: 1); }
  .card1 .moon{font-size: 24px;color: $glowing-purple;margin-bottom: 0;
    text-align: center;}
  .card1 .marBtn{border-radius: 50px;
    padding: 5px 10px;margin: 10px;
    cursor: pointer;

    box-shadow: 0px 0px 12px 4px rgba($color: $pink, $alpha: 1); }
  .card1 .mar{font-size: 24px;color: $pink;margin-bottom: 0;
    text-align: center;
    cursor: pointer;
}
  .card1 .starBtn{border-radius: 50px;
    padding: 5px 10px;margin: 10px;
    box-shadow: 0px 0px 12px 4px rgba($color: $blue, $alpha: 1); }
  .card1 .star{font-size: 24px;color: $blue;margin-bottom: 0;
    text-align: center;
    cursor: pointer;
}
  .card1 .glxyBtn{border-radius: 50px;
    padding: 5px 10px;margin: 10px;
    cursor: pointer;

    box-shadow: 0px 0px 12px 4px rgba($color: $light-blue, $alpha: 1); }
  .card1 .glxy{font-size: 24px;color: $light-blue;margin-bottom: 0;
    text-align: center;}

  .card1 sup{font-size: 55%;
    color: #000;
  }
  .card1 .btnTab{position: relative;
    top: 15%;
    float: right;}
  .card1 .text{text-align: right;
    margin-right: 15px;
    font-size: 16px;margin-bottom: 5px;
  }

  .hdr{margin-top: 5%;
    padding-left: 2%;text-transform: capitalize;}
  .card2{background: $tab-button;
    padding: 50px 30px;
    border-radius: 50px;margin-top: 2%;}
  .card2 .col_moon{height: 200px;max-width: 20%;
    border-radius: 30px;
    box-shadow: 0px 0px 12px 4px rgba($color: $glowing-purple, $alpha: 1);    margin-left: 2.5%;
    margin-right: 2.5%;padding: 30px;
  }
  .card2 .col_moon h5{color:$glowing-purple ;margin-bottom: 15px;}
  .card2 .col_mar{height: 200px;max-width: 20%;
    border-radius: 30px;
    box-shadow: 0px 0px 12px 4px rgba($color: $pink, $alpha: 1);    margin-left: 2.5%;
    margin-right: 2.5%;padding: 30px;
  }
  .card2 .col_mar h5{color:$pink ;margin-bottom: 15px;}
  .card2 .col_star{height: 200px;max-width: 20%;
    border-radius: 30px;
    box-shadow: 0px 0px 12px 4px rgba($color: $blue, $alpha: 1);    margin-left: 2.5%;
    margin-right: 2.5%;padding: 30px;
  }
  .card2 .col_star h5{color:$blue ;margin-bottom: 15px;}
  .card2 .col_galaxy{height: 200px;max-width: 20%;
    border-radius: 30px;
    box-shadow: 0px 0px 12px 4px rgba($color: $light-blue, $alpha: 1);    margin-left: 2.5%;
    margin-right: 2.5%;padding: 30px;
  }
  .card2 .col_galaxy h5{color:$light-blue ;margin-bottom: 15px;}
  .card2 p{font-size: 20px;}
  @media screen and (max-width: 580px) {
    .card2 .col_moon{height: 200px;max-width: 190px;
        border-radius: 30px;
        box-shadow: 0px 0px 12px 4px rgba($color: $glowing-purple, $alpha: 1);    
        margin-left: 20.5%;
        margin-right: 4.5%;padding: 30px;
      }
      .card2 .col_moon h5{color:$glowing-purple ;margin-bottom: 15px;}
      .card2 .col_mar{height: 200px;max-width: 190px;
        border-radius: 30px;
        box-shadow: 0px 0px 12px 4px rgba($color: $pink, $alpha: 1);    
        margin-left: 20.5%;
        margin-top: 26px;
        margin-right: 4.5%;
        padding: 30px;
      }
      .card2 .col_mar h5{color:$pink ;margin-bottom: 15px;}
      .card2 .col_star{height: 200px;max-width: 190px;
        border-radius: 30px;
        box-shadow: 0px 0px 12px 4px rgba($color: $blue, $alpha: 1);    
        margin-left: 20.5%;
        margin-top: 26px;
        margin-right: 4.5%;
        padding: 30px;
      }
      .card2 .col_star h5{color:$blue ;margin-bottom: 15px;}
      .card2 .col_galaxy{height: 200px;max-width: 190px;
        border-radius: 30px;
        box-shadow: 0px 0px 12px 4px rgba($color: $light-blue, $alpha: 1);   
        margin-left: 20.5%;
        margin-top: 26px;
        margin-right: 4.5%;
        padding: 30px;
      }  
  }