@import '../../styles/variables';
@import '../../styles/mixins';

.card {
  padding: 30px 30px;
  border-radius: 20px;
  box-shadow: 0px 0px 40px 5px rgba($color: $glowing-button, $alpha: 1.0);
  background: rgba(53, 176, 230, 1);
  margin-top: 30px;
  background: -moz-linear-gradient(
    45deg,
    #001f68 0%,
    #140035 100%
  );
  background: -webkit-gradient(
    left bottom,
    right top,
    #001f68 0%,
    #140035 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #001f68 0%,
    #140035 100%
  );
  background: -o-linear-gradient(
    45deg,
    #001f68 0%,
    #140035 100%
  );
  background: -ms-linear-gradient(
    45deg,
    #001f68 0%,
    #140035 100%
  );
  background: linear-gradient(
    45deg,
    #001f68 0%,
    #140035 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5b42a2', endColorstr='#140035', GradientType=1 );
  @include media-breakpoint-range (tab-up) {
    margin-top: 60px;
  }
  @include media-breakpoint-range (tab-landscape-up) {
    padding: 60px 50px;
    margin-top: 0px;
  }

  h3 {
    font-size: 20px;
    letter-spacing: 10px;
    border-bottom: 2px solid $border-grey;
    padding-bottom: 25px;
    margin-bottom: 40px;
    color: #fff;
  }

  .fact {
    font-size: 11px;
    letter-spacing: 1px;
    padding-bottom: 7.5px;
    margin-bottom: 7.5px;
    border-bottom: 2px solid $border-grey;

    .label {
      padding-left: 10px;
      color: #fff;
    }

    .value {
      word-break: break-all;
      color: #fff;
    }
  }

}
